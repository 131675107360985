import { useEffect } from "react";
import { Box } from "@mui/material";
import { SectionWrapper, TitleWrapper } from "../../../styles/sectionStyles";
import { Project } from "./Project";
import mobileAppProjects from "../../../data/MobileAppProjects.json";
import webAppProjects from "../../../data/WebAppProjects.json";
export const ProjectsContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionWrapper>
      <TitleWrapper>PROJECTS</TitleWrapper>
      <div className="proj-statement">I am currently engaged in multiple exciting projects, which unfortunately cannot be showcased at this time. However, I will have the pleasure of sharing them with you in the near future. Thank you for your interest.</div>
      <div className="proj-types-title">Web Applications</div>
      <Box>
        {webAppProjects.map((project,ind) => (
          <Project project={project} key={ind}/>
        ))}
      </Box>
      <div className="proj-types-title">Mobile Applications</div>
      <Box>
        {mobileAppProjects.map((project,ind) => (
          <Project project={project} key={ind}/>
        ))}
      </Box>
    </SectionWrapper>
  ); 
};
