import Bluewinds from "../assets/bluewinds.jpeg";
import Brace from "../assets/brace.jpeg";
import Codlers from "../assets/codlers.jpeg";
import Meals4uBuness from "../assets/meal4u_business.jpg";
import ValuLife from "../assets/valulife.jpg";
import FlashLead from "../assets/flashlead.jpg";
import CedaMoney from "../assets/cedamoney.jpg";
import FlashProperty from "../assets/flashproperty.jpg";
import WeRemind from "../assets/weremind.jpg";
import Ideyvex from "../assets/ideyvex.png";
import FLashLeadWA from "../assets/fl-wa.jpg";
import FlashPropertyWA from "../assets/fp-wa.png";
import Buildjoy from "../assets/buildjoy.png";


interface Map {
  [key: string]: string | undefined;
}


export const logo: Map = {
  bluewinds: Bluewinds,
  brace: Brace,
  codlers: Codlers
};

export enum Constants {
  USERNAME = "Mukash Wasti",
}


export const projectImage: Map = {
  "Meals4U Business": Meals4uBuness,
  "ValuLife": ValuLife,
  "FlashLead Mobile App": FlashLead,
  "CedaMoney": CedaMoney,
  "Flash Property Mobile App": FlashProperty,
  "WeRemind": WeRemind,
  "Ideyvex": Ideyvex,
  "FlashLead": FLashLeadWA,
  "Flash Property": FlashPropertyWA,
  "Buildjoy": Buildjoy
};
